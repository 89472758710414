import { changeTypePrice, calOffer, changeTypePrice_format, changeTypePrice_format_gross, create_url_image } from "@/models/changeType"

type serch_fild = {
  group: string;
  search: string;
  limit: string;
  page: number;
  sort_field: string;
  sort_type: string;
  prod_type: string;
  has_quantity: number;
  tags: string;
  min_price: string;
  max_price: string;
  tag_condition: string;
  block_id: string;
  title: object;
}

type result = {
  count: number;
  offer: string;
  price: number;
  priceFormat: string;
  Is_Bulk: string;
  org_id: string;
  id: string;
  image: string;
  max_request: string;
  min_order: string;
  max_order: string;
  onhand: string;
  title: string;
  unit_title: string;
  lot_size: string;
  price_gross: number;
  price_grossFormat: string;
}

const state = {
  searchFild: {
    page: 1,
    has_quantity: 1,
    tags: '',
    title: {},
  } as serch_fild,
  result: [] as result[],
  load_page: false,
  not_result:false,
  filter_search_filds: {},
  count_result: ''
}

const mutations = {
  search_fild: (state, paylod) => {
    state.searchFild[paylod.type] = paylod.fild
  },
  result_product: (state, paylod) => {
    state.result = paylod
    state.load_page = false
    if (paylod.length == 0) {
      state.not_result = true
      return
    } else {
      state.not_result = false
    }
  },
  load_page: (state, paylod) => {
    state.load_page = paylod
  },
  see_more: (state, paylod) => {
    for (const product of paylod) {
      state.result.push(product)
    }
    state.load_page = false
  },
  filter_search_filds: (state, paylod) => {
    state.filter_search_filds = paylod
  },
  count_result: (state, payload) => {
    state.count_result = payload
  },
  clear_search_result: (state) => {
    state.result = []
  }
}

const actions = {
  search_fild: ({commit}, fild) => {
    commit('search_fild', fild)
  },
  load_page: ({commit}, paylod) => {
    commit('load_page', paylod)
  },
  result: ({commit}, result) => {
    let results = [] as result[]
    if (result.length != 0) {
    commit('count_result', result[0].count)
    for (const product of result) {
      let offer = ''
        if (product.orgs[0].Prod_Gross_Price != product.orgs[0].Prod_Price && product.orgs[0].Prod_Gross_Price != 0) {
          offer = calOffer({
          price: product.orgs[0].Prod_Price,
          gross_price: product.orgs[0].Prod_Gross_Price,
          typeToChange: 'percent',
          TypeToJoin: '%'
          })
        }
      const pro = {
        Packing_Weight: product.weight,
        count: 0,
        offer: offer,
        price: changeTypePrice({
          price: product.orgs[0].Prod_Price,
          Tax_price: product.orgs[0].Tax_Price
        }),
        priceFormat: changeTypePrice_format({
          price: product.orgs[0].Prod_Price,
          Tax_price: product.orgs[0].Tax_Price,
        }),
        price_format_pack: changeTypePrice_format({
          price: product.orgs[0].Prod_Price * product.weight,
          Tax_price: product.orgs[0].Tax_Price,
        }),
        Is_Bulk: product.bulk,
        org_id: product.orgs[0].Org_ID_FK,
        id: product.id,
        image: create_url_image(product.image, 200, 200),
        max_request: product.max_request,
        min_order: product.orgs[0].Min_Order,
        max_order: product.orgs[0].Max_Order,
        onhand: product.max_request,
        title: product.title,
        unit_title: product.orgs[0].Unit_Title,
        lot_size: product.orgs[0].Lot_Size,
        price_gross: changeTypePrice({
          price: product.orgs[0].Prod_Gross_Price,
          Tax_price: product.orgs[0].Tax_Price
        }),
        price_grossFormat: changeTypePrice_format_gross({
          price: product.orgs[0].Prod_Gross_Price,
          Tax_price: product.orgs[0].Tax_Price,
        })
      }
      results.push(pro)
    }
    }
    commit('result_product', results)
  },
  see_more: ({commit}, result) => {
    let results = [] as result[]
    for (const product of result) {
      let offer = ''
          if (product.orgs[0].Prod_Gross_Price != product.orgs[0].Prod_Price && product.orgs[0].Prod_Gross_Price != 0) {
            offer = calOffer({
            price: product.orgs[0].Prod_Price,
            gross_price: product.orgs[0].Prod_Gross_Price,
            typeToChange: 'percent',
            TypeToJoin: '%'
          })
          }
      const pro = {
        count: 0,
        offer: offer,
        price: changeTypePrice({
          price: product.orgs[0].Prod_Price,
          Tax_price: product.orgs[0].Tax_Price
        }),
        priceFormat: changeTypePrice_format({
          price: product.orgs[0].Prod_Price,
          Tax_price: product.orgs[0].Tax_Price,
        }),
        price_format_pack: changeTypePrice_format({
          price: product.orgs[0].Prod_Price * product.weight,
          Tax_price: product.orgs[0].Tax_Price,
        }),
        Is_Bulk: product.bulk,
        org_id: product.orgs[0].Org_ID_FK,
        id: product.id,
        image: create_url_image(product.image, 200, 200),
        max_request: product.max_request,
        min_order: product.orgs[0].Min_Order,
        max_order: product.orgs[0].Max_Order,
        onhand: product.max_request,
        title: product.title,
        unit_title: product.orgs[0].Unit_Title,
        lot_size: product.orgs[0].Lot_Size,
        price_gross: changeTypePrice({
          price: product.orgs[0].Prod_Gross_Price,
          Tax_price: product.orgs[0].Tax_Price
        }),
        price_grossFormat: changeTypePrice_format_gross({
          price: product.orgs[0].Prod_Gross_Price,
          Tax_price: product.orgs[0].Tax_Price,
        })
      }
      results.push(pro)
    }
    commit('see_more', results)
  },
  filter_search_filds: ({commit}, filds) => {
    commit('filter_search_filds', filds)
  },
  clear_search_result: ({commit}) => {
    commit('clear_search_result')
  }
}

export default { state, mutations, actions }