import Vue from 'vue'
import Vuex from 'vuex'
import basket from '../views/shop/Basket/store/basket'
import home from '../views/shop/home/store/home'
import factors from '../views/Factor/store/factors'
import user from '../views/UserProfile/store/user'
import purse from '../views/Wallet/store/purse'
import address from '../views/Address/store/address'
import search from '@/views/shop/search/store/search'
import productDetail from '@/views/shop/productDetail/store/productDetail'
import categories from '@/views/shop/categories/store/categories'
import auth from '@/views/auth/store/auth'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    alert: {
      success: {
        show: false,
        type: ''
      },
      error: {
        show: false,
        type: ''
      }
    },
    loadingSubmit: false,
    loading_page: false,
    content: {}
  },
  mutations: {
    submit: (state,paylod) => {
      state.loadingSubmit = paylod
    },
    alertSuccess: (state,paylod) => {
      state.alert.success.type = paylod.type
      state.alert.success.show = paylod.show
    },
    alertError: (state,paylod) => {
      state.alert.error.type = paylod.type
      state.alert.error.show = paylod.show
    },
    load_page: (state, paylod) => {
      state.loading_page = paylod
    },
    content: (state, payload) => {
      state.content = payload
    }
  },
  actions: {
    submit: ({commit}, paylod) => {
      commit('submit',paylod)
    },
    alertSuccess: ({commit}, paylod) => {
      commit('alertSuccess',paylod)
    },
    alertError: ({commit}, paylod) => {
      commit('alertError',paylod)
    },
    load_page: ({commit}, paylod) => {
      commit('load_page', paylod)
    },
    content: ({commit}, payload) => {
      commit('content', payload)
    }
  },
  modules: {
    basket,
    home,
    factors,
    user,
    purse,
    address,
    search,
    auth,
    productDetail,
    categories
  }
})
