import { get_config } from '@/models/changeType';
import '@mdi/font/css/materialdesignicons.css'
import axios from 'axios';
// axios.defaults.baseURL = "https://masihamarket.ir/"
import Vue from 'vue';
import Vuetify from 'vuetify';
Vue.use(Vuetify);
get_config()
let config = {} as any
config = localStorage.getItem('config')
if (!config) {
  axios.get('/api/Store/Config/get').then(Response => {
    localStorage.setItem('config', JSON.stringify(Response.data.Result))
    axios.post('/api/Social/Galleries/getGalleryItems',{
      gallery_id: Response.data.Result.logo_galler_id
    }).then(Response => {
      localStorage.setItem('logo', Response.data.Result[2].thumbnail)
      location.reload()
    })
  })
}
const _config = JSON.parse(config)
const themes = JSON.parse(_config.theme)
if (!themes) {
  localStorage.removeItem('config')
  axios.get('/api/Store/Config/get').then(Response => {
    localStorage.setItem('config', JSON.stringify(Response.data.Result))
    location.reload()
  })
}
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-style';
    link.rel = 'stylesheet';
    link.href = `/template/mobile_category/style/${_config.client_id}.css?v=123456789`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-57x57')
    link.id = 'apple-icon-57x57'
    link.rel = 'apple-touch-icon'
    link.href = `/template/mobile_category/images/icons/${_config.client_id}/apple-icon-57x57.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-60x60');
    link.id = 'apple-icon-60x60';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile_category/images/icons/${_config.client_id}/apple-icon-60x60.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-72x72');
    link.id = 'apple-icon-72x72';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile_category/images/icons/${_config.client_id}/apple-icon-72x72.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-76x76');
    link.id = 'apple-icon-76x76';
    link.rel = 'apple-touch-icon';
    link.href =  `/template/mobile_category/images/icons/${_config.client_id}/apple-icon-76x76.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-114x114');
    link.id = 'apple-icon-114x114';
    link.rel = 'apple-touch-icon';
    link.href =  `/template/mobile_category/images/icons/${_config.client_id}/apple-icon-114x114.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-120x120');
    link.id = 'apple-icon-120x120';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile_category/images/icons/${_config.client_id}/apple-icon-120x120.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-144x144');
    link.id = 'apple-icon-144x144';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile_category/images/icons/${_config.client_id}/apple-icon-144x144.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-152x152');
    link.id = 'apple-icon-152x152';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile_category/images/icons/${_config.client_id}/apple-icon-152x152.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-180x180');
    link.id = 'apple-icon-180x180';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile_category/images/icons/${_config.client_id}/apple-icon-180x180.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('android-icon-192x192');
    link.id = 'android-icon-192x192';
    link.rel = 'icon';
    link.href =`/template/mobile_category/images/icons/${_config.client_id}/android-icon-192x192.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('favicon-32x32');
    link.id = 'favicon-32x32';
    link.rel = 'icon';
    link.type = "image/png"
    link.href = `/template/mobile_category/images/icons/${_config.client_id}/favicon-32x32.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }


  // <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('favicon-96x96');
    link.id = 'favicon-96x96';
    link.rel = 'icon';
    link.type = "image/png"
    link.href = `/template/mobile_category/images/icons/${_config.client_id}/favicon-96x96.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }


  // <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('favicon-16x16');
    link.id = 'favicon-16x16';
    link.rel = 'icon';
    link.type = "image/png"
    link.href = `/template/mobile_category/images/icons/${_config.client_id}/favicon-16x16.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }


  // <meta name="msapplication-TileColor" content="#ffffff">
  if (_config) {
    let link = document.createElement('meta'),
    oldLink = document.getElementById('msapplication-TileColor');
    link.id = 'msapplication-TileColor';
    link.name = 'msapplication-TileColor';
    link.content = "#ffffff"
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }

  // <meta name="msapplication-TileImage" content="/ms-icon-144x144.png">
  if (_config) {
    let link = document.createElement('meta'),
    oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-style';
    link.name = 'msapplication-TileImage'
    link.content = `/template/mobile_category/images/icon/${_config.client_id}/ms-icon-144x144.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }

  // <meta name="theme-color" content="#ffffff">
  if (_config) {
    let link = document.createElement('meta'),
    oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-style';
    link.name = 'theme-color'
    link.content = themes.light.primary
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
export default new Vuetify({
    rtl: true,
    icons: {
       iconfont: 'mdiSvg',
    },
    theme: {
      themes: {
        light:
        {
          primary: '#1d4923',
          lightPrimary: '#49754c',
          darkPrimary: '#002100',
          secondary: '#ff500a', //loadings, progress bars
          lightSecondary: '#ff8440',
          darkSecondary: '#c30c00',
          accent: '#CCE3CF',
          lightAccent: '#ffffff',
          darkAccent: '#9bb19e',
          surface: '#fff', //cards, sheets, menus, virtual scrollers
          background: '#f9f9f9',
          primaryActions: '#1d4923', //bottons
          secondaryActions: '#1d4923', //add to basket bottons
          actionNavColor: '#fff', //bottom action navbar in mobile version
          headerColor: '#fff', //primary header
          secondHeader: '#1d4923', //secondary header or submenu
          disableColor: '#E0E0E0',
          darkDisable: '#7E7C7C',
          lightDisable: '#FAFAFA',
          footerBg: '#f4f4f4',
          error: '#B71C1C',
          lightError: '#FFD5D5',
          errorBg: '#F9E3E5',
          success: '#0e7b46',
          lightSuccess: '#D9F2DD',
          successBg: '#E1F9EE',
          warning: '#FFA000',
          warningBg: '#FBEAD0',
          info: '#1E88E5',
          offLabel: '#ff500a',
          //////////text and typography/////////
          onOffLable: '#fff',
          onFooter: '#212121',
          onHeader: '#ffffff',
          onSecondHeader: '#fff',
          onPrimaryActions: '#fff',
          onSecondaryActions: '#fff',
          onActionNav: '#ff500a',
          onAccent: '#1d4923',
          onPrimary: '#fff',
          onSecondary: '#fff',
          onLightPrimary: '#fff',
          onDarkPrimary: '#fff',
          onLightSecondary: '#212121',
          onDarkSecondary: '#fff',
          onLightAccent: '#212121',
          onDarkAccent: '#212121',
          onSurface: '#212121',
          onBackground: '#212121',
          primaryText: '#1d4923',
          secondaryText: "#ff500a",
          accentText: '#FFFFFF',
      }
      }
    }
});