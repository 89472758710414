import { create_url_image } from "@/models/changeType"

const state = {
  user: null,
  invite_list: null
}

const mutations = {
    setUser: (state,paylod) => {
        state.user = paylod
    },
    remove_image: (state, paylod) => {
      state.user.image = paylod
    },
    invite_list: (state, paylod) => {
      state.invite_list = paylod
    },
}

const actions = {
    setUser: ({commit}, paylod) => {
        commit('setUser',paylod)
    },
    remove_image: ({commit}, paylod) => {
      commit('remove_image', paylod)
    },
    invite_list: ({commit}, paylod) => {
      const _list = [] as any
      if (paylod.islist == true) {
        for (const users of paylod.list) {
          const user = {
            Customer_ID: users.Customer_ID,
            Nick_Name: users.Nick_Name,
            Photo_Path: create_url_image(users.Photo_Path,30,30)
          }
          _list.push(user)
        }
        commit('invite_list', _list)
      } else {
        commit('invite_list', paylod.list)
      }
    }

}

export default { state, mutations, actions }