import { changeTypePrice_format } from "@/models/changeType"

type address = {
    id: string;
    address: string;
    phone: string;
    mobile: string;
}
const state = {
    UserAddress: [] as address[],
    viewAddress: null,
    Provinces: [''],
    ProvincesObject: null,
    citys: [''],
    citysObject: null,
    zones: [],
    zones_title: []
}

const mutations = {
    removeAddressUser: (state,paylod) => {
        const address = state.UserAddress.find(p => p.id == paylod)
        if (address) {
          const index =state.UserAddress.indexOf(address)
          state.UserAddress.splice(index, 1)
        }
    },
      setAddressUser: (state,paylod) => {
        state.UserAddress = paylod
      },
      viewAddressUser: (state,paylod) => {
        state.viewAddress = paylod
      },
      Provinces: (state,paylod) => {
        const Provinces = ['--استان خودرا انتخاب کنید--']
        for (const iterator of paylod) {
          Provinces.push(iterator.value)
        }
        state.Provinces = Provinces
        state.ProvincesObject = paylod
      },
      ProvinceCitys: (state,paylod) => {
        const citys = ['--شهر خودرا انتخاب کنید--']
        for (const iterator of paylod) {
          citys.push(iterator.value)
        }
        state.citys = citys
        state.citysObject = paylod
      },
      set_zone: (state, payload) => {
        state.zones = payload.zones
        state.zones_title = payload.title
      }
}

const actions = {
      removeAddressUser: ({commit}, paylod) => {
        commit('removeAddressUser', paylod)
      },
      setAddressUser: ({commit}, paylod) => {
        const addresses = [] as any
        for (const address of paylod) {
          const _address = {
            id: address.id,
            title: address.title,
            address: address.address,
            city: address.city,
            city_id: address.city_id,
            free_shipment_min_price: address.free_shipment_min_price,
            free_shipment_min_price_type: changeTypePrice_format({
              price: address.free_shipment_min_price,
              Tax_price: ''
            }),
            mobile: address.mobile,
            phone: address.phone,
            post_price: changeTypePrice_format({
              price: address.post_price,
              Tax_price: ''
            }),
            postal_code: address.postal_code,
            province: address.province,
            province_id: address.province_id,
            receiver: address.receiver,
            type: address.type,
            zone_id: address.zone_id,
            zone_title: address.zone_title
          }
          addresses.push(_address)
        }
        commit('setAddressUser',addresses)
      },
      viewAddressUser: ({commit}, paylod) => {
        commit('viewAddressUser',paylod)
      },
      Provinces: ({commit}, paylod) => {
        commit('Provinces',paylod)
      },
      ProvinceCitys: ({commit}, paylod) => {
        commit('ProvinceCitys',paylod)
      },
      set_zone: ({commit}, payload) => {
        let zones = [] as any
        let zones_title = [] as any
        for (const zone of payload) {
          const _zone = {
            title: zone.Zone_Title,
            id: zone.Zone_ID_PK
          }
          zones_title.push(zone.Zone_Title)
          zones.push(_zone)
        }
          commit("set_zone", {zones:zones, title: zones_title})
      }
}

export default { state, mutations, actions }