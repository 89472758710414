const state = {
 purse: [''],
 purse_info: [''],
}
const mutations = {
    purse: (state,paylod) => {
        state.purse = paylod
      },
      purselist: (state,paylod) => {
        state.purse.push(paylod)
      },
      purseInfo: (state,paylod) => {
        state.purse_info = paylod
      },
      purseInfolist: (state,paylod) => {
          state.purse_info.push(paylod)
      },
}
const actions = {
    purse: ({commit}, paylod) => {
        commit('purse',paylod)
      },
      purselist: ({commit}, paylod) => {
        commit('purselist',paylod)
      },
      purseInfo: ({commit}, paylod) => {
        commit('purseInfo',paylod)
      },
      purseInfolist: ({commit}, paylod) => {
        commit('purseInfolist',paylod)
      },
}

export default { state, mutations, actions }