import { changeTypePrice, calOffer, changeTypePriceFactor, changeTypePrice_format, changeTypePrice_format_gross, changeTypePrice_factor, create_url_image, create_gallery_resize } from "@/models/changeType"
import router from "@/router"
import { viewbasket_products } from '../models/Basket'
type product_basket = {
  count:  number;
  price: number;
  price_formate: string;
  price_gross: number;
  price_gross_format: string;
  onhand: string;
  min_order: string;
  max_order: string;
  max_request: string;
  lot_size: string;
  id: string;
  error: string;
  image: string;
  lead_time: string;
  org_id: string;
  org_title: string;
  title: string;
  unit_title: string;
  Is_Bulk: string;
}
type product_detail = {
  count: string | number;
  price: number;
  priceFormate: string;
  price_gross: number;
  priceGrossFormate: string;
  onhand: string;
  min_order: string;
  max_order: string;
  max_request: string;
  lot_size: string;
  id: string;
  error: string;
  image: string;
  lead_time: string;
  org_id: string;
  org_title: string;
  title: string;
  unit_title: string;
  Is_Bulk: string;
}
type delivery_Time = {
  cargo_id_pk: string;
  deliveries: object;
}
type set_time_basket = {
  cargoId: string;
  date: string;
  shift: string;
}
type coupons = {
  id: string;
  type: string;
  price: string;
}
type items_error_basket = {
  Prod_Title: string;
  Gen_Prod_ID_PK: string;
  Prod_Image: string;
  Prod_Sub_Title: string;
}
type error_time ={
  time: string;
  products: [];
}
const state = {
    basket: [] as product_basket[],
    payment_defult: {},
    priceBasket: 0,
    basketCount: [],
    addressBasket_Id: '',
    delivery_Time: [],
    product_detail: null,
    similarProducts: [],
    coupons: [] as coupons[],
    price_offer_coupon: 0,
    tax_offer_coupon: 0,
    Balance: 0,
    payment_after_coupon: '',
    payment_after_purse: '',
    set_time_basket: [] as set_time_basket[],
    purses: [],
    empty_payment: 0,
    Tah_coupon: 0,
    error_coupon: '',
    error_time: [] as error_time[],
    addBasket_status: true,
    items_basket: [],
    items_error_basket: [],
    way_pay: '',
    cost_payment: 0,
    addBasket_error: false,
    load_page_basket: false
}

const mutations = {
  clear_basket: (state) => {
    state.basket = []
  },
    basket: (state,paylod) => {
        state.basket = paylod
        state.basketCount = paylod
        localStorage.setItem('basket', JSON.stringify(paylod))
    },
    payment_defult: (state, paylod) => {
      state.payment_defult = paylod
      let post_cost = paylod.post_price_constant
      state.cost_payment = (parseInt(paylod.gross_price) + parseInt(post_cost))
      state.payment_after_coupon = changeTypePrice_factor({
        price: (parseInt(paylod.gross_price) + parseInt(post_cost)),
        Tax_price: paylod.tax,
      })
      state.payment_after_purse = changeTypePrice_factor({
        price: (parseInt(paylod.gross_price) + parseInt(post_cost)),
        Tax_price: paylod.tax,
      })
    },
    price_basket: (state, paylod) => {
      state.priceBasket = changeTypePrice({
        price: parseInt(paylod.total),
        Tax_price: paylod.tax
      })
    },
      addcountBasket: (state, paylod) => {
        const count = state.basketCount.find(c => c.id == paylod.product_id)
        if (!count) {
          if (paylod.weight) {
            const product = {
              count: paylod.count * paylod.weight,
              org_id: paylod.org_id,
              id: paylod.product_id
            }
            state.basketCount.push(product)
          } else {
            const product = {
              count: paylod.count,
              org_id: paylod.org_id,
              id: paylod.product_id
            }
            state.basketCount.push(product)
          }
        } else {
          if (paylod.weight) {
            count.count = paylod.count * paylod.weight
          } else {
            count.count = paylod.count
          }
        }
        localStorage.setItem('basket', JSON.stringify(state.basketCount))
        console.log(state.basketCount.length)
      },
      removecountBasket: (state, paylod) => {
        const count = state.basketCount.find(c => c.id == paylod.product_id)
        if (count) {
          const index = state.basketCount.indexOf(paylod)
          state.basketCount.splice(index, 1)
        }
        localStorage.setItem('basket', JSON.stringify(state.basketCount))
        console.log(state.basketCount)
      },
      addressBasket: (state, addressID) => {
        state.addressBasket = addressID
      },
      removeBasket: (state,paylod) => {
        const product = state.basket.find(p => p.id == paylod.product_id)
        if (product) {
          const index =state.basket.indexOf(product)
          state.basket.splice(index, 1)
          state.priceBasket = parseInt(state.priceBasket) - (parseInt(paylod.price))
        }
        const items_error = state.items_error_basket.find(E => E.Gen_Prod_ID_PK == paylod.product_id)
        if (items_error) {
          const index =state.items_error_basket.indexOf(items_error)
          state.items_error_basket.splice(index, 1)
        }
      },
      addBasket: (state,paylod) => {
        const product = state.basket.find(p => p.id == paylod.product.product_id)
        
        if (product) {
          product.count = paylod.product.count
          switch (paylod.type) {
            case '+':
              state.priceBasket = parseInt(state.priceBasket) + (parseInt(paylod.price))
              break;
              case '-':
              state.priceBasket = parseInt(state.priceBasket) - (parseInt(paylod.price))
              break;
          }
        }
      },
      productDetail: (state, paylod) => {
        state.product_detail = paylod
      },
      similarProduct: (state, paylod) => {
        state.similarProducts = paylod
      },
      coupon: (state, paylod) => {
        state.coupon = paylod
      },
      deliveries: (state, paylod) => {
        state.delivery_Time = paylod
      },
      set_time_basket: (state, paylod) => {
        const cargo = state.set_time_basket.find(T => T.cargoId == paylod.cargoId)
        const error = state.error_time.find(E => E.time == paylod.date)
        if (error.products.length == 0) {
          if (cargo) {
            cargo.date = paylod.date
            cargo.shift = paylod.shift
          } else {
            state.set_time_basket.push(paylod)
          }
        } else {
          let items = [] as items_error_basket[]
          for (const product of error.products) {
            const item_error = state.items_basket.find(p => p.Gen_Prod_ID_PK == product)
            items.push(item_error)
          }
          state.items_error_basket = items
        }
      },
      purse_basket: (state, paylod) => {
        const purse = state.purses.find(p => p.id == paylod.id)
        if (purse) {
          purse.Balance = paylod.Balance
        } else {
          state.purses.push(paylod)
        }
      },
      cal_purse: (state, paylod) => {
        state.empty_payment = 0
        let post_cost = state.payment_defult.post_price_constant
        if (state.Balance > parseInt(state.payment_defult.gross_price) + parseInt(post_cost)) {
          state.empty_payment = 1
          state.payment_after_purse = 0
          state.cost_payment = 0
          const purse = state.purses.find(p => p.id == paylod.id)
          purse.remain = changeTypePrice_format({
            price: parseInt(paylod.Balance),
            Tax_price: '0'
          })
          purse.deducted = changeTypePrice_format({
            price: '0',
            Tax_price: '0'
          })
          state.Balance = parseInt(state.Balance) +parseInt(paylod.Balance)
        } else {
          if (state.empty_payment == 0) {
            if (state.payment_defult.show_tax) {
              const prise = ((parseInt(state.payment_defult.gross_price) - parseInt(state.price_offer_coupon)) +
              (parseInt(state.payment_defult.tax) - parseInt(state.tax_offer_coupon))) - state.Balance
              state.payment_after_purse = changeTypePrice_factor({
               price: prise + parseInt(post_cost),
               Tax_price: state.payment_defult.tax,
             })
            } else {
              let prise = 0
              if (parseInt(state.price_offer_coupon) > (parseInt(state.payment_defult.gross_price) + parseInt(post_cost))) {
                if (state.Balance > parseInt(post_cost)) {
                  state.payment_after_purse = 0
                  state.cost_payment = 0
                  const purse = state.purses.find(p => p.id == paylod.id)
                  purse.remain = changeTypePrice_format({
                    price: parseInt(paylod.Balance),
                    Tax_price: '0'
                  })
                  purse.deducted = changeTypePrice_format({
                    price: '0',
                    Tax_price: '0'
                  })
                  state.Balance = parseInt(state.Balance) +parseInt(paylod.Balance)
                  return
                }
                prise = parseInt(post_cost) - state.Balance
              } else {
                prise = (((parseInt(state.payment_defult.gross_price) + parseInt(post_cost)) - parseInt(state.price_offer_coupon))) - state.Balance
              }
              if (parseInt(paylod.Balance) > prise) {
                const purse = state.purses.find(p => p.id == paylod.id)
                purse.remain = changeTypePrice_format({
                  price: parseInt(paylod.Balance) - prise,
                  Tax_price: '0'
                })
                purse.deducted = changeTypePrice_format({
                  price: (parseInt(paylod.Balance) - (parseInt(paylod.Balance) - prise)),
                  Tax_price: '0'
                })
                purse.use = true
                state.payment_after_purse = '0'
                state.cost_payment = 0
                state.Balance = parseInt(state.Balance) + parseInt(paylod.Balance)
              } else {
                const purse = state.purses.find(p => p.id == paylod.id)
                purse.remain = '0'
                purse.deducted = changeTypePrice_format({
                  price: (parseInt(paylod.Balance)),
                  Tax_price: '0',
                })
                state.payment_after_purse = changeTypePrice_format({
                  price: (prise - parseInt(paylod.Balance)),
                  Tax_price: '0',
                })
                state.cost_payment = (prise - parseInt(paylod.Balance))
                purse.use = true
                state.Balance = parseInt(state.Balance) + parseInt(paylod.Balance)
              }
            }
          }
        }
      },
      dont_use_purse: (state, paylod) => {
        const Balance = parseInt(paylod.Balance)
        let post_cost = state.payment_defult.post_price_constant
        state.empty_payment = 0
        const purse = state.purses.find(p => p.id == paylod.id)
        if (purse) {
          purse.deducted = '0'
          purse.remain = changeTypePrice_format({
            price: Balance,
            Tax_price: '0'
          })
          purse.use = false
        }
        let all_balance = 0
        for (const purse of state.purses) {
          if (purse.use == true) {
            all_balance = all_balance + parseInt(purse.Balance)
          }
        }
        if (parseInt(state.price_offer_coupon) > (parseInt(state.payment_defult.gross_price) + parseInt(post_cost))) {
          if (all_balance > parseInt(post_cost)) {
            state.payment_after_purse = changeTypePrice_format({
              price: 0,
              Tax_price: '0',
            })
            state.cost_payment = 0
            return
          }
          state.payment_after_purse = changeTypePrice_format({
            price: parseInt(post_cost) - all_balance,
            Tax_price: '0',
          })
          state.cost_payment = parseInt(post_cost) - all_balance
          return
        }
        state.payment_after_purse = changeTypePrice_format({
          price: ((state.payment_defult.gross_price + parseInt(post_cost)) - parseInt(state.price_offer_coupon)) - all_balance,
          Tax_price: '0',
        })
        state.cost_payment = ((state.payment_defult.gross_price + parseInt(post_cost)) - parseInt(state.price_offer_coupon)) - all_balance
      },
      cal_coupon: (state, paylod) => {
        state.error_coupon = ''
        const coupon = state.coupons.find(c => c.id == paylod.id)
        let post_cost = state.payment_defult.post_price_constant
        if (!coupon) {
          state.coupons = (paylod)
          state.empty_payment = 0
            switch (paylod.type) {
              case 'percent':
                let price_offer = ((parseInt(state.payment_defult.gross_price) * paylod.price) / 100)
                if (price_offer > paylod.max_price) {
                  price_offer = paylod.max_price
                }
                const tax_offer = ((parseInt(state.payment_defult.tax) * paylod.price) / 100)
                state.price_offer_coupon =  parseInt(state.price_offer_coupon) + price_offer
                state.tax_offer_coupon =  parseInt(state.tax_offer_coupon) + tax_offer
                state.Tah_coupon = changeTypePrice_format({
                  price: price_offer,
                  Tax_price: ((parseInt(state.payment_defult.tax) * paylod.price) / 100).toString()
                })
                break;
              case 'cash':
                state.Tah_coupon = changeTypePrice_format({
                  price: paylod.price,
                  Tax_price: '0'
                })
                state.price_offer_coupon =  parseInt(state.price_offer_coupon) + parseInt(paylod.price)
                if (parseInt(state.payment_defult.gross_price) < parseInt(paylod.price)) {
                  state.empty_payment = 1
                  state.payment_after_coupon = changeTypePrice_factor ({
                    price: post_cost,
                    Tax_price: state.payment_defult.tax,
                  })
                  state.cost_payment =  post_cost
                }
                state.tax_offer_coupon =  parseInt(state.price_offer_coupon) + parseInt(paylod.price)
                break
            }
            if (state.empty_payment == 0) {
              if (state.payment_defult.show_tax) {
                state.payment_after_coupon = changeTypePrice_factor({
                  price: (((parseInt(state.payment_defult.gross_price) - state.price_offer_coupon)) + parseInt(post_cost)),
                  Tax_price: ((parseInt(state.payment_defult.tax) - state.tax_offer_coupon)).toFixed(0),
                })
              } else {
                state.payment_after_coupon = changeTypePrice_factor({
                  price: (((parseInt(state.payment_defult.gross_price) - state.price_offer_coupon)) + parseInt(post_cost)),
                  Tax_price: state.payment_defult.tax,
                })
                state.cost_payment = (((parseInt(state.payment_defult.gross_price) - state.price_offer_coupon)) + parseInt(post_cost))
              }
            }
        }
        let all_balance = 0
        for (const purse of state.purses) {
          if (purse.use == true) {
            all_balance = all_balance + parseInt(purse.Balance)
          }
        }
        if (parseInt(state.price_offer_coupon) > (parseInt(state.payment_defult.gross_price) + parseInt(post_cost))) {
          if (all_balance > parseInt(post_cost)) {
            state.payment_after_purse = changeTypePrice_format({
              price: 0,
              Tax_price: '0',
            })
            state.cost_payment = 0
            return
          }
          state.payment_after_purse = changeTypePrice_format({
            price: parseInt(post_cost) - all_balance,
            Tax_price: '0',
          })
          state.cost_payment = parseInt(post_cost) - all_balance
          return
        }
        state.payment_after_purse = changeTypePrice_format({
          price: ((state.payment_defult.gross_price + parseInt(post_cost)) - parseInt(state.price_offer_coupon)) - all_balance,
          Tax_price: '0',
        })
        state.cost_payment = ((state.payment_defult.gross_price + parseInt(post_cost)) - parseInt(state.price_offer_coupon)) - all_balance
      },
      refresh_balance: (state) => {
        state.Balance = 0
        state.empty_payment == 0
        state.refresh = true
        setTimeout(() => {
          state.refresh = false
        }, 800);
      },
      cliear_coupon: (state, paylod) => {
        state.error_coupon = ''
        state.coupons = []
        state.price_offer_coupon = 0
        state.tax_offer_coupon = 0
        state.empty_payment = 0
        state.Tah_coupon = 0
        state.Balance = 0
        state.refresh = true
        setTimeout(() => {
          state.refresh = false
        }, 800);
      },
      invalid_coupon: (state, paylod) => {
        state.error_coupon = ''
        setTimeout(() => {
          state.error_coupon = paylod
        }, 800);
      },
      addBasket_status: (state, paylod) => {
        state.addBasket_status = paylod
      },
      error_time: (state, paylod) => {
        const error = state.error_time.find(T => T.time == paylod.time)
        if (error) {
          error.products = paylod.products
        } else {
          state.error_time.push(paylod)
        }
      },
      items_basket: (state, paylod) => {
        state.items_basket = paylod
      },
      way_pay: (state, paylod) => {
        state.way_pay = paylod
      },
      clear_time: (state) => {
        state.set_time_basket = []
      },
      addBasket_error: (state, paylod) => {
        state.addBasket_error = paylod
      },
      load_page_basket: (state, payload) => {
        state.load_page_basket = payload
      }
}

const actions = {
  basket_local: ({commit}, payload) => {
    commit('basket', payload)
  },
  addressBasket: ({commit}, paylod) => {
    commit('addressBasket', paylod)
  },
    removeBasket: ({commit}, paylod) => {
        commit('removeBasket',paylod)
      },
      addcountBasket: ({commit}, paylod) => {
        commit('addcountBasket', paylod)
      },
      removecountBasket: ({commit}, paylod) => {
        commit('removecountBasket', paylod)
      },
      addBasket: ({commit}, paylod) => {
        commit('addBasket',paylod)
      },
      basket: ({commit}, basket) => {
        let basket_product = [] as product_basket[]
        for (const product of basket.products) {
          const prod = {
            count: product.basket.count,
            price: changeTypePrice({
              price: product.info.priceWithoutFormat,
              Tax_price: product.info.tax_price
            }),
            price_format_pack: changeTypePrice_format({
              price: product.info.Packing_Weight * product.info.priceWithoutFormat,
              Tax_price: product.info.tax_price,
            }),
            Packing_Weight: product.info.Packing_Weight,
            price_formate: changeTypePrice_format({
              price: product.info.priceWithoutFormat,
              Tax_price: product.info.tax_price,
            }),
            price_gross: changeTypePrice({
              price: product.info.price_gross,
              Tax_price: product.info.tax_price
            }),
            price_gross_format: changeTypePrice_format_gross({
              price: product.info.price_gross,
              Tax_price: product.info.tax_price,
            }),
            tax: product.info.tax_price,
            onhand: product.info.onhand,
            min_order: product.info.min_order,
            max_order: product.info.max_order,
            max_request: product.info.max_request,
            lot_size: product.info.lot_size,
            id: product.info.id,
            error: product.info.error,
            image: create_url_image(product.info.image, 85,85),
            lead_time: product.info.lead_time,
            org_id: product.info.org_id,
            org_title: product.info.org_title,
            title: product.info.title,
            unit_title: product.info.unit_title,
            Is_Bulk: product.info.Is_Bulk
          }
          basket_product.push(prod)
        }
        commit('basket', basket_product)
        commit('price_basket', basket.basket)
      },
      coupon: ({commit}, coupon) => {
        commit('commit', coupon)
      },

      productDetail: ({commit}, product) => {
        if (!product) {
          commit('productDetail', null)
          return
        }
        const product_detail = {
          Brand_Title: product.Brand_Title,
          Is_Bulk: product.Is_Bulk,
          price_format_pack: changeTypePrice_format({
            price: product.Packing_Weight * product.orgs[0].Prod_Price,
            Tax_price: product.orgs[0].Tax_Price,
          }),
          max_order: product.orgs[0].Max_Order,
          min_order: product.orgs[0].Min_Order,
          org_id: product.orgs[0].Org_ID_FK,
          org_title: product.orgs[0].Org_Title,
          comments: product.comments,
          description: product.raw_description,
          Packing_Weight: product.Packing_Weight,
          group: product.group,
          id: product.id,
          image: create_url_image(product.image_resize, 270,270),
          onhand: product.onhand,
          priceGross: changeTypePrice({
            price: product.orgs[0].Prod_Gross_Price,
            Tax_price: product.orgs[0].Tax_Price
          }),
          price: changeTypePrice({
            price: product.orgs[0].Prod_Price,
            Tax_price: product.orgs[0].Tax_Price
          }),
          priceGrossFormate: changeTypePrice_format_gross({
            price: product.orgs[0].Prod_Gross_Price,
            Tax_price: product.orgs[0].Tax_Price,
          }),
          priceFormate: changeTypePrice_format({
            price: product.orgs[0].Prod_Price,
            Tax_price: product.orgs[0].Tax_Price,
          }),
          rate: product.rate,
          title: product.title,
          evaluations: product.evaluations,
          gallery: create_gallery_resize( product.gallery, 270, 270),
          unit_title: product.unit_title,
          review_description: product.review_description,
          lot_size: product.orgs[0].Lot_Size
        }
        setTimeout(() => {
          const basket = localStorage.getItem('basket')
         if (basket) {
           commit('basket', JSON.parse(basket))
         } else {
          viewbasket_products()
         }
        }, 500);
        commit('productDetail', product_detail)
      },
      similarProducts: async ({commit}, products) => {
        let similar = [] as product_detail[]
        for (const product of products) {
          let offer = ''
      if (product.orgs[0].Prod_Gross_Price != product.orgs[0].Prod_Price && product.orgs[0].Prod_Gross_Price != 0) {
        offer = calOffer({
        price: product.orgs[0].Prod_Price,
        gross_price: product.orgs[0].Prod_Gross_Price,
        typeToChange: 'percent',
        TypeToJoin: '%'
      })
      }
        const product_detail = {
          count: '0',
          Is_Bulk: product.Is_Bulk,
          max_order: product.orgs[0].Max_Order,
          min_order: product.orgs[0].Min_Order,
          org_id: product.orgs[0].Org_ID_FK,
          org_title: product.orgs[0].Org_Title,
          price_format_pack: changeTypePrice_format({
            price: product.Packing_Weight * product.orgs[0].Prod_Price,
            Tax_price: product.orgs[0].Tax_Price,
          }),
          offer: offer,
          id: product.id,
          image: create_url_image(product.image, 200, 200),
          onhand: product.onhand,
          max_request: product.onhand,
          lead_time: '1',
          error: product.error,
          price_gross: changeTypePrice({
            price: product.orgs[0].Prod_Gross_Price,
            Tax_price: product.orgs[0].Tax_Price
          }),
          price: changeTypePrice({
            price: product.orgs[0].Prod_Price,
            Tax_price: product.orgs[0].Tax_Price
          }),
          priceGrossFormate: changeTypePrice_format_gross({
            price: product.orgs[0].Prod_Gross_Price,
            Tax_price: product.orgs[0].Tax_Price,
          }),
          priceFormate: changeTypePrice_format({
            price: product.orgs[0].Prod_Price,
            Tax_price: product.orgs[0].Tax_Price,
          }),
          title: product.title,
          unit_title: product.unit_title,
          lot_size: product.orgs[0].Lot_Size,
          Packing_Weight: product.Packing_Weight
        }
         similar.push(product_detail)
        }
        commit('similarProduct', similar)
      },
      addBasket_error: ({commit}, paylod) => {
        commit('addBasket_error', paylod)
      },
      deliveries: ({commit}, cargos) => {
        if (!cargos.deliveries) {
          router.push('/reciever')
          return
        }
        commit('deliveries', {deliveries: cargos, cargos_id: cargos.cargo_id_pk})
        commit('items_basket', cargos.items)
        for (const error of cargos.deliveries) {
          if (error.full_capacity_list) {
            const error_time = {
              time: error.solar_date,
              products: error.full_capacity_list
            }
            commit('error_time', error_time)
          }
        }
      },
      set_time_basket: ({commit}, Time) => {
        commit('set_time_basket', Time)
      },
      purse_basket: ({commit}, purses) => {
        for (const purse of purses) {
          if (purse.Balance != 0) {
            const wallet = {
              id: purse.purse_id,
              Customer_ID: purse.Customer_ID,
              Purse_Title: purse.purse_title,
              Purse_Type: purse.Purse_Type,
              Purse_State: purse.Purse_State,
              namedKind: purse.namedKind,
              Balance: purse.balance,
              deducted: '0',
              remain: changeTypePrice_format({
                price: purse.balance,
                Tax_price: '0'
              }),
              balance_show: changeTypePrice_format({
                price: purse.balance,
                Tax_price: '0'
              }),
              use: true
            }
            commit('purse_basket', wallet)
            commit('cal_purse', wallet)
          }
        }
      },
      payment_price: ({commit}, offer) => {
        commit('payment_price', offer)
      },
      payment: ({commit}, price) => {
        let post_cost = changeTypePrice_format({
          price: price.post_price_constant,
          Tax_price: '',
        })
        const paymant = {
          cargo_id_pk: price.cargoes[0].cargo_id_pk,
          gross_price: changeTypePrice({
            price: price.cargoes[0].gross_price,
            Tax_price: price.tax
          }),
          gross_price_format: changeTypePrice_format({
            price: price.cargoes[0].gross_price,
            Tax_price: price.tax
          }),
          tax: price.tax,
          tax_format: changeTypePrice_format({
            price: price.tax,
            Tax_price: price.tax,
          }),
          post_price_constant: price.post_price_constant,
          post_price_format: post_cost,
          post_thershold: price.post_thershold
        }
        commit('payment_defult', paymant)
      },
      cal_coupon: ({commit}, coupon) => {
        commit('refresh_balance')
        setTimeout(() => {
          commit('cal_coupon', {id:coupon.code, type: coupon.type, price: coupon.amount, max_price: coupon.max_price})
        }, 500);
      },
      refresh_balance: ({commit}) => {
        commit('refresh_balance')
      },
      cliear_coupon: ({commit}) => {
        commit('cliear_coupon')
        setTimeout(() => {
          commit('cal_coupon', {id:0, type: 'cash', price: 0})
        }, 500);
      },
      back_payment: ({commit}) => {
        commit('cliear_coupon')
      },
      cal_purse: ({commit}, purse) => {
        commit('cal_purse', purse)
      },
      cal_purse_true: ({commit}, purse) => {
        commit('cal_purse_true', purse)
      },
      dont_use_purse: ({commit}, purse) => {
        commit('dont_use_purse', purse)
      },
      invalid_coupon: ({commit}, text) => {
        commit('invalid_coupon', text)
      },
      addBasket_status: ({commit}, status) => {
        commit('addBasket_status', status)
      },
      way_pay: ({commit}, way) => {
        commit('way_pay', way)
      },
      clear_time: ({commit}) => {
        commit('clear_time')
      },
      load_page_basket: ({commit}, payload) => {
        commit('load_page_basket', payload)
      },
      clear_basket: ({commit}) => {
        commit('clear_basket')
      }
}

export default { state, mutations, actions }