
const state = {
    comments_product: []
  }
  
  const mutations = {
    comments_product: (state,paylod) => {
          state.comments_product = paylod
    },
  }
  
  const actions = {
    comments_product: ({commit}, comments) => {
          commit('comments_product', comments)
    },
  }
  
  export default { state, mutations, actions }